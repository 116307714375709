/*
 * DEBOUNCE
  var example = debounce(function() {
  }, 500);
 */
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/*
 * Add leading zeros
 * https://gist.github.com/endel/321925f6cafa25bbfbde
 */
Number.prototype.pad = function(size) {
  var sign = Math.sign(this) === -1 ? '-' : '';
  return sign + new Array(size).concat([Math.abs(this)]).join('0').slice(-size);
}

const debouncedRequestAnimationFrame = function(timeoutId, callback) {
  if (timeoutId)
    window.cancelAnimationFrame(timeoutId);

  return timeoutId = window.requestAnimationFrame(callback);
};

const setRootVariables = () => {
  document.documentElement.style.setProperty('--window-width', `${ document.body.offsetWidth }px`);
  document.documentElement.style.setProperty('--window-height', `${ window.innerHeight }px`);
}

let rafForResizeId = undefined;
window.addEventListener('resize', event => {
  rafForResizeId = debouncedRequestAnimationFrame(rafForResizeId, () => {
    setRootVariables();
  });
});


/*
 * DOCUMENT READY
 */
$( document ).ready(function() {
  setRootVariables();

  //* Reload pages after 5 minutes inactive
  //Add localstorage reload-timeout
  let mouseMoveTimeout = undefined;
  const timoutReloadMilSec = 300000;
  const searchParams = new URLSearchParams(window.location.search);
  const reloadTimeout = searchParams.get('reload');

  if(localStorage.getItem('reload-timeout') == null)
    if(reloadTimeout != null)
      localStorage.setItem('reload-timeout', true);

  //Timeout reload
  if(localStorage.getItem('reload-timeout')) {
    mouseMoveTimeout = setTimeout(() => {
      location.reload();
    }, timoutReloadMilSec);

    document.addEventListener('mousemove', () => {
      if(mouseMoveTimeout)
        clearTimeout(mouseMoveTimeout);

      mouseMoveTimeout = setTimeout(() => {
        location.reload();
      }, timoutReloadMilSec);
    });
  }


  if(document.querySelector('[data-go-back]'))
    document.querySelector('[data-go-back]').addEventListener('click', () => {

      if(window.location.href.includes('agenda')) {
        const searchParam = new URLSearchParams(window.location.search);

        if(searchParam.has('place') && document.referrer)
          window.location = '/mapa';
        else
          window.location = '/';
      }
      else if(window.location.href.includes('atividade')) {
        if(document.referrer)
          window.history.back();
        else
          window.location = '/';
      }
      else {
        window.location = '/';
      }

    });
});
